<script>
import crudMixin from '@/mixins/crud';
import APIDate from '@/services/API/Date';
import APIProduct from '@/services/API/Product';

export default {
  mixins: [
    crudMixin('date', () => ({
      datetime: null,
      products: [],
    }), APIDate),
  ],
  data: () => ({
    products: [],
  }),
  methods: {
    beforeAddDate(data) {
      if (data.datetime) {
        const datetime = this.$moment(data.datetime);
        data.datetime = datetime.format('YYYY-MM-DD');
      }

      return data;
    },
  },
  created() {
    APIProduct.get()
      .then((products) => (this.products = products));
  },
};
</script>

<template>
  <section class="section">
    <header class="is-flex is-justify-content-space-between">
      <h1 class="title">
        Produits
      </h1>
      <b-button class="flex-none" type="is-primary" @click="addDate">
        Ajouter
      </b-button>
    </header>
    <table class="table is-bordered is-fullwidth">
      <tr>
        <th>Date</th>
        <th>Options</th>
      </tr>
      <tr v-for="item in date.list" :key="item.id">
        <td>
          {{ item.datetime }}
        </td>
        <td class="has-text-right">
          {{ item.products.map((v) => v.name).join(', ') }}
        </td>
        <td>
          <b-button
            class="has-text-danger"
            type="is-text"
            size="is-small"
            @click="deleteDate(item)">
            Supprimer
          </b-button>
        </td>
      </tr>
    </table>
    <b-modal width="520px" :active.sync="date.isFormOpen">
      <form class="box" @submit.prevent="isDataValid() && handleDate()">
        <h2 class="title is-4">
          Ajouter
        </h2>
        <b-field label="Date" label-for="datetime">
          <b-datepicker v-model="date.form.datetime" />
        </b-field>
        <b-field
          v-for="(product, k) in products"
          :key="k"
          label="Produit"
          :label-for="`name${k}`">
          <b-checkbox
            v-model="date.form.products"
            :id="`name${k}`"
            :native-value="{
              id: product.id,
              name: product.name,
              price: product.price,
              summary: product.summary,
            }">
            {{ product.name }}
          </b-checkbox>
        </b-field>
        <b-field>
          <b-button type="is-primary" native-type="submit" expanded>
            Envoyer
          </b-button>
        </b-field>
      </form>
    </b-modal>
  </section>
</template>
