import camelCase from 'lodash/camelCase';

export default (key, form, api) => ({
  data: () => ({
    [key]: {
      isFetching: false,
      isFormOpen: false,
      list: [],
      form: form(),
    },
  }),
  created() {
    console.log(this);
    this[key].isFetching = true;
    api.get()
      .then((data) => (this[key].list = data))
      .finally(() => (this[key].isFetching = false));
  },
  methods: {
    isDataValid() {
      if (this.$v) {
        this.$v.$touch();

        if (this.$v.$error) {
          return false;
        }
      }

      return true;
    },
    [camelCase(`add-${key}`)]() {
      this[key].isFormOpen = true;
    },
    [camelCase(`delete-${key}`)](item) {
      this.$buefy.dialog.confirm({
        message: 'La suppression est définitive',
        onConfirm: () => api.remove(item.id)
          .then(() => (this[key].list = this[key].list
            .filter((v) => v.id !== item.id))),
      });
    },
    [camelCase(`handle-${key}`)]() {
      const beforeAdd = this[camelCase(`before-add-${key}`)] || ((data) => data);
      const filledForm = beforeAdd({ ...this[key].form });

      if (!this.isDataValid()) {
        return;
      }

      api.add(filledForm)
        .then((data) => {
          this[key].list.push(data);
          this[key].isFormOpen = false;
          this[key].form = form();
          const onSuccessKey = camelCase(`on${key}AddSuccess`);
          if (this[onSuccessKey]) {
            onSuccessKey(data);
          }
        });
    },
  },
});
